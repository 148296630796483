import React, { useEffect } from 'react';

import { Routes, Route, Outlet, Link } from 'react-router-dom';
import "antd/dist/antd.css";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Home = React.lazy(() => import('./pages/home'));
const Details = React.lazy(() => import('./pages/details'));
const AddClient = React.lazy(() => import('./pages/addClient/index.js'));
const Auth = React.lazy(() => import('./pages/auth/index.js'));
function App() {

	const user = useSelector(state => (state.auth ? state.auth.user : {}))
	const {
		isLoggedin = false
	} = user


	return (
		<>
			<ToastContainer
				position="bottom-right"
				autoClose={2500}
				newestOnTop={false}
				rtl={false}
				pauseOnVisibilityChange={false}
			/>

			<Routes>
				<Route path="/" element={<Layout />} fallback={<div>Loading... </div>}>
					{/* <Route index element={<Home />} fallback={<div>Loading... </div>} /> */}

					<Route
						index
						element={
							<React.Suspense fallback={<>Loading...</>}>
								<Auth />
							</React.Suspense>
						}
					/>
					{
						isLoggedin === true ? <>
							<Route
								path="home"
								element={
									<React.Suspense fallback={<>Loading...</>}>
										<Home />
									</React.Suspense>
								}
							/>




							<Route
								path="detail/:id"
								element={
									<React.Suspense fallback={<>Loading...</>}>
										<Details />
									</React.Suspense>
								}
							/>
							<Route
								path="add"
								element={
									<React.Suspense fallback={<>...</>}>
										<AddClient />
									</React.Suspense>
								}
							/></> : null
					}

					<Route path="*" element={<NoMatch />} />
				</Route>
			</Routes>
		</>
	);
}

function Layout() {

	const user = useSelector(state => (state.auth ? state.auth.user : {}))
	const {
		isLoggedin = false
	} = user

	useEffect(() => {

	}, [user])
	const dispatch = useDispatch()

	let history = useNavigate()
	const logOut = () => {
		let payload = {
			isLoggedin: false
		}
		dispatch({ type: 'SIGN_IN', payload: payload })
		history("/")
	}
	return (
		<div >
			<nav style={{ display: isLoggedin === true ? "default" : "none" }}>
				<ul style={{ display: 'flex', justifyContent: 'space-around' }}>
					<li>
						<Link to="/home">Home</Link>
					</li>
					{/* <li>
						<Link to="/detail/1">Detail</Link>
					</li> */}
					<li>
						<Link to="/add">Add</Link>
					</li>
					<li>
						<button className="btn btn-primary" onClick={() => { logOut() }}>Log out</button>
					</li>
				</ul>
			</nav>

			<hr />

			<Outlet />
		</div>
	);
}

function NoMatch() {
	return (
		<div style={{

			display: "flex",
			flexFlow: "column",
			justifyContent: "center",
			alignItems: "center",
		}}>
			<h2 style={{ color: "red" }}> Not allowed </h2>
			<p>
				<Link to="/">Go to the home page</Link>
			</p>
		</div>
	);
}

export default App;
